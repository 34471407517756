@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css);
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");

.card {
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
  border: none;
}

.card-body {
  padding: 0;
  margin: 0;
}

.btn-primary {
  border-color: #5a8dee !important;
  background-color: #5a8dee !important;
  color: #fff;
}

.form-submit {
  padding: 13px 30px;
  /* font-size: 15px; */
  letter-spacing: 0.3px;
  font-weight: 400;
}

.kb-data-box {
  width: 100%;
  flex: 1;
}

.kb-modal-data-title {
  margin-bottom: 10px;
}

.kb-data-title h6 {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 600;
}

.kb-file-upload {
  /* margin-bottom: 20px; */
}

textarea:focus-within{
  border: none;
}

.file-upload-box {
  border: 0.1em dotted #0B0947;
  background-color: #282760;
  border-radius: 4px;
  min-height: 8em;
  position: relative;
  overflow: hidden;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #8194aa;
  font-weight: 400;
  /* font-size: 15px; */
}

.file-upload-box .file-upload-input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.file-link {
  text-decoration: underline;
  margin-left: 3px;
}

.file-upload-box .file-link:hover {
  text-decoration: none;
}

.file-atc-box {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.file-image {
  width: 5em;
  height: 5em;
  object-fit: contain;
  object-position: top;
  background-size: cover;
  border-radius: 5px;
  margin-right: 1em;
  background-color: #282760;
  display: flex;
  align-items: center;
  justify-content: center;
  /* font-size: 30px; */
  color: #475f7b;
  padding: 0.5em;
}

.file-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
}

.file-detail {
  flex: 1;
  width: calc(100% - 210px);
}

.file-detail h6 {
  word-break: break-all;
  /* font-size: 13px; */
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 8px;
}

.file-detail p {
  /* font-size: 12px; */
  color: #8194aa;
  line-height: 0.5em;
  font-weight: 400;
  margin-bottom: 0px;
}

.file-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.file-action-btn {
  /* font-size: 12px; */
  color: #8194aa;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0;
  background-color: transparent;
  border: none;
  text-decoration: underline;
  margin-right: 15px;
  cursor: pointer;
}

.file-action-btn:hover {
  color: #3d546f;
  text-decoration: underline;
}

.file-atc-box:last-child {
  margin-bottom: 0;
}

.kb-attach-box {
  max-height: 20em;
  overflow-y: scroll;
}

.fileupload-view .row > div {
  padding: 0 !important;
}

.card {
  border: none !important;
  box-shadow: none !important;
}

@media (min-width: 576px) {
  .back-button {
    font-size: 1.2em;
  }
}

@media (min-width: 768px) {
  .back-button {
    font-size: 1.5em;
  }

  .file-detail p {
    line-height: 1.5em;
  }
}

@media (min-width: 1200px) {
  .back-button {
    font-size: 2em;
  }
  .file-image {
    width: 6em;
    height: 6em;
    margin-right: 2em;
    padding: 1em;
  }
  .file-detail p {
    line-height: 2em;
  }
  .file-upload-box {
    min-height: 10em;
  }
  .card {
    margin-bottom: 2em;
  }
}
