.uc-thumbnail-bg {
  background-color: #282760;
  border: 0.1em dotted #0b0947;
  text-align: center;
  padding: 2em;
  border-radius: 0.8rem;
}

.uc-thumbnail-bg-sm {
  background-color: #282760;
  /* border: 0.1em dotted #0B0947; */
  text-align: center;
  padding: 0.8em;
  border-radius: 0.6em;
  position: relative;
  width: 100%;
}

.uc-thumbnail-bg-view {
  background-color: #282760;
  border: 0.1em dotted #0b0947;
  text-align: center;
  padding: 2em;
  border-radius: 0.8rem;
}

#profile-image-upload {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}

.thumbnail-preview-img {
  width: 2em;
  height: 2em;
}

@media (min-width: 425px) {
  .uc-thumbnail-bg {
    padding: 3em;
  }
}

@media (min-width: 576px) {
  .uc-thumbnail-bg {
    padding: 4em;
  }
}

@media (min-width: 992px) {
  .uc-thumbnail-bg-sm {
    position: absolute;
    width: calc(100% - 2em);
  }
  .thumbnail-container {
    padding-right: 2em;
  }
}

@media (min-width: 1200px) {
  .uc-thumbnail-bg {
    padding: 5em;
  }
  .thumbnail-preview-img {
    width: 3em;
    height: 3em;
  }
}
