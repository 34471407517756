.payment-modal {
  width: auto;
  height: auto;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3em 4em;
}
.dropdown-toggle::after {
  display: none !important;
}
.steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
  color: #929292;
}
.steps > .step {
  position: relative;
  display: table-cell;
  text-align: center;
  font-size: 0.875rem;
  color: #6d6875;
}
.steps > .step:before {
  content: attr(data-step);
  display: block;
  margin: 0 auto;
  background: #ffffff;
  border: 2px solid #e6e6e6;
  color: #e6e6e6;
  width: 2rem;
  height: 2rem;
  text-align: center;
  margin-bottom: -4.2rem;
  line-height: 1.9rem;
  border-radius: 100%;
  position: relative;
  z-index: 1;
  font-weight: 700;
  font-size: 1rem;
}
.steps > .step:after {
  content: "";
  position: absolute;
  display: block;
  background: #e6e6e6;
  width: 100%;
  height: 0.125rem;
  top: 1rem;
  left: 50%;
}
.steps > .step:last-child:after {
  display: none;
}
.steps > .step.is-complete {
  color: #6d6875;
}
.steps > .step.is-complete:before {
  content: "✓";
  color: #164d15;
  background: #fef0e2;
  border: 2px solid #164d15;
}
.steps > .step.is-complete:after {
  background: #164d15;
}
.steps > .step.is-active {
  font-size: 1rem;
}
.steps > .step.is-active:before {
  color: #fff;
  border: 2px solid #164d15;
  background: #164d15;
  margin-bottom: -4.9rem;
}
.progress-bar-steps {
  height: 0.25rem;
  background: gray;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  /* filter: grayscale(1); */
}
.text-pg {
  position: absolute;
  background-color: white;
  padding: 0 1rem;
  width: 100px;
  border-radius: 5px;
  color: gray;
  top: 0;
  transform: translateY(-150%);
  visibility: hidden;
  text-align: center;
  font-size: 10px;
}
.br-pg {
  /* background: #164d15; */
  background: #276626;
  height: 100%;
  width: 20%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.progress-step-container:hover > .text-pg {
  visibility: visible;
}
.progress-step-container {
  position: absolute;
  height: 15px;
  width: 15px;
  background: white;
  border-radius: 50%;
  top: 0;
  transform: translateY(-6px);
  color: white;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  font-weight: bolder;
  justify-content: center;
  cursor: pointer;
}
.progress-step-11 {
  left: 10%;
  transform: translateX(50%) translateY(-6px);
}
.progress-step-12 {
  left: 25%;
  transform: translateX(50%) translateY(-6px);
}
.progress-step-13 {
  right: 0%;
  transform: translateX(-50%) translateY(-6px);
  /* transform: translateX(-50%) translateY(-8px); */
}
