a {
  text-decoration: none;
  color: black;
}

/* a:visited{
    color: black;
  } */

.notification-box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
  border-radius: 5px;
}

.notification-box::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
}

.notification-box::-webkit-scrollbar-thumb {
  background-color: black;
  border: 2px solid black;
  border-radius: 5px;
}

header {
  -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  height: 110px;
  vertical-align: middle;
}

.icons {
  display: inline;
  float: right;
  color: white;
}

.notification {
  z-index: 100000000000000;
  position: relative;
  display: inline-block;
}

.number {
  height: 15px;
  width: 15px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: 20%;
  display: flex;
  right: 25%;
  border-style: solid;
  border-width: 1px;
  font-size: 8px;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.number:empty {
  display: block !important;
}

.notBtn {
  transition: 0.5s;
  cursor: pointer;
}

.fas {
  font-size: 25pt;
  padding-bottom: 10px;
  color: black;
  margin-right: 40px;
  margin-left: 40px;
}

.notification-box {
  width: 350px;
  height: 0px;
  z-index: 1000000007928347981947198 !important;
  border-radius: 10px;
  transition: 0.5s;
  position: absolute;
  overflow-y: scroll;
  padding: 0px;
  left: -300px;
  margin-top: 5px;
  background-color: #02012d !important;
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.1);
  cursor: context-menu;
}

.fas:hover {
  color: #d63031;
}
.notification-open {
  height: 60vh !important;
}

/* .notBtn:hover > .notification-box{
    height: 60vh
  } */

.content {
  padding: 20px;
  color: black;
  vertical-align: middle;
  text-align: left;
}

.gry {
  background-color: #f4f4f4;
}

.top {
  color: black;
  padding: 10px;
}

.display {
  position: relative;
}

.cont-notification {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  flex-direction: column;
}

.cont-notification:empty {
  display: block;
}

.stick {
  text-align: center;
  display: block;
  font-size: 50pt;
  padding-top: 70px;
}

.stick:hover {
  color: black;
}

.cent {
  text-align: center;
  display: block;
}

.sec {
  padding: 25px 10px;
  background-color: #02012d;
  transition: 0.5s;
}

.profCont {
  padding-left: 15px;
}

.profile {
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  width: 75px;
  float: left;
}

.txt {
  vertical-align: top;
  font-size: 1rem;
  padding: 5px 10px 0px 115px;
}

.sub {
  font-size: 1rem;
  color: grey;
}

/* .new{
    border-style: none none solid none;
    border-color: red;
  } */

.sec:hover {
  background-color: #bfbfbf;
}

.notification-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #02012d;
  color: white;
  font-size: 15px;
  font-family: Clash Dispaly Bold;
  border-bottom: 1px solid white;
}
.notification-header-container button {
  background-color: #02012d;
  border: none;
  font-size: 12px;
  font-family: Clash Dispaly Bold;
  color: #434bde;
}
.card-link .content {
  padding: 0 !important;
}
