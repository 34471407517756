:root{
  --padding-side-overall-tb : 2em;
  --padding-side-overall-lr: 2em;
  --padding-side-overall-r: 2.5em;
  --padding-side-overall-b :2.5em
}

.container-dashboard {
  background: linear-gradient(
    192.1deg,
    rgba(255, 122, 122, 0.56) 14.23%,
    #2a0fd3 101.22%
  );
  padding-left: var(--padding-side-overall-lr) !important;
  padding-right: var(--padding-side-overall-lr) !important;
  padding-top: var(--padding-side-overall-tb) !important;
  padding-bottom: var(--padding-side-overall-b) !important;
  width: 100%;
  margin: 0;
}
.input-icons i {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.input-icons {
  width: 100%;
  height: 100% !important;
  background: linear-gradient(
    270.13deg,
    rgba(0, 0, 0, 0.5) 0.09%,
    rgba(0, 0, 0, 0.5) 88.52%
  );
  backdrop-filter: blur(18.9472px);
  border-radius: 10.9907px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.input-icons input {
  width: 100%;
  height: 100% !important;
  /* background: linear-gradient(
    270.13deg,
    rgba(0, 0, 0, 0.5) 0.09%,
    rgba(0, 0, 0, 0.5) 88.52%
  ); */
  border-radius: 10.9907px;
  flex: none;
  order: 0;
  flex-grow: 0;
  color: white;
  font-weight: 400;
  font-size: 15.4811px;
  line-height: 140%;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.input-icons input::placeholder {
  color: white;
  font-weight: 400;
  font-size: 15.4811px;
  line-height: 140%;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.input-icons input:focus,
.input-icons input:focus-within,
.input-icons input:focus-visible {
  outline: none;
}
.bar-1{
  width: 18%;
  height: 10px;
  /* background-color: green; */
  margin-left: 3px;
  border: 1px solid white;
  position: relative;
}
.bar-1 p{
  display: none;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: white;
  transform: translate(-50%,70%);
  color: #02012d;
  padding: 1px 10px;
  border-radius: 8px;
  width: max-content;
  font-size: 12px;
  font-family: "Clash Display Medium";
}
.bar-1:hover > p{
  display: block;
}
.input-icons {
  width: 80% !important;
  max-width: 300px !important;
  background: transparent;
  border-radius: 10.9907px;
  flex: none;
  order: 0;
  flex-grow: 0;
  border: transparent;
}
.icon-down-announcement{
  border-radius: 50%;
  right: 0;
  bottom: 0;
  display: flex;
  height: 35px;
  width: 35px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  background-color: #02012d;
  padding: 5px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
}
.icon-announcement{
  position: absolute;
  top: 0;
}
.icon-up-announcement{
  border-radius: 50%;
  right: 0;
  bottom: 0;
  display: flex;
  width: 35px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  background-color: #02012d;
  padding: 5px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  transition: all 1s;
  /* transform: rotate(180deg) translateY(-100%); */
}
.icon-down-announcement{
  border-radius: 50%;
  right: 0;
  bottom: 0;
  display: flex;
  width: 35px;
  color: white;
  font-size: 20px;
  cursor: pointer;
  background-color: #02012d;
  padding: 5px;
  width: fit-content;
  text-align: center;
  cursor: pointer;
  transition: all 1s;
  /* transform: rotate(180deg) ; */
  transform: translateY(150%);
}
.box-announcement{
  width: 100%;
  height: auto;
  transition: all 1s ease-in-out;
  animation: slideDown 1.2s;
}
.opened{
 display: none !important;
}

@keyframes slideDown {
  from{
   transform: translateY(-100%);
  }
  to{
    transform: translateY(0%);
  }
}
.div-animated-open{
  margin-top: 0px;
  animation: slideUp 1s;
}
@keyframes slideUp {
  from{
   margin-top: -67px;
  }
  to{
    margin-top: 0px;
  }
}
.animated-div-down{
  transform: translateY(0%);
  display: block;
}
.animated-div-up{
  transform: translateY(-100%);
  display: none;
}
.icons-dashboard {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14.6543px;
  gap: 5.99px;
  width: 52.25px;
  height: 100% !important;
  background: #492430;
  backdrop-filter: blur(18.9472px);
  border-radius: 10.9907px;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.nc-footer,
.nc-bell-button-dot {
  display: none !important;
}
.nc-layout-wrapper {
  background-color: #02012d !important;
}
.nc-header-title {
  font-family: "Clash Display Bold";
}
.nc-header-mark-as-read,
.nc-notifications-list-item-title,
.nc-notifications-list-item-timestamp {
  font-family: "Clash Display Medium";
}
.btn-create:hover {
  border: 1px solid white !important;
  background-color: #2a0fd3 !important;
  color: white;
}
.text-overview-header {
  font-family: "Clash Display Bold";
  font-size: 15px;
  color: white;
}
.text-header-top {
  margin: 0 !important;
  margin-right: auto !important;
  width: max-content !important;
}

@media (min-width: 375px) {
}
@media (min-width: 560px) {
  .container-dashboard{
    padding-left: calc(var(--padding-side-overall-lr)*1.1) !important;
    padding-right: calc(var(--padding-side-overall-r)*1.1) !important;
    padding-top: calc(var(--padding-side-overall-tb)*1.1) !important;
    padding-bottom: calc(var(--padding-side-overall-b)*1.1) !important;
  }
}
@media (min-width: 768px) {
  .container-dashboard{
    padding-left: calc(var(--padding-side-overall-lr)*1.2) !important;
    padding-right: calc(var(--padding-side-overall-r)*1.2) !important;
    padding-top: calc(var(--padding-side-overall-tb)*1.2) !important;
    padding-bottom: calc(var(--padding-side-overall-b)*1.2) !important;
  }
}
@media (min-width: 992px) {
  .container-dashboard{
    padding-left: calc(var(--padding-side-overall-lr)*1.3) !important;
    padding-right: calc(var(--padding-side-overall-r)*1.3) !important;
    padding-top: calc(var(--padding-side-overall-tb)*1.3) !important;
    padding-bottom: calc(var(--padding-side-overall-b)*1.3) !important;
  }
}
@media (min-width: 1200px) {
  .container-dashboard{
    padding-left: calc(var(--padding-side-overall-lr)*1.4) !important;
    padding-right: calc(var(--padding-side-overall-r)*1.4) !important;
    padding-top: calc(var(--padding-side-overall-tb)*1.4) !important;
    padding-bottom: calc(var(--padding-side-overall-b)*1.4) !important;
  }
}
@media (min-width: 1400px) {
  .container-dashboard{
    padding-left: calc(var(--padding-side-overall-lr)*1.5) !important;
    padding-right: calc(var(--padding-side-overall-r)*1.5) !important;
    padding-top: calc(var(--padding-side-overall-tb)*1.5) !important;
    padding-bottom: calc(var(--padding-side-overall-b)*1.5) !important;
  }
}
@media (min-width: 1800px) {
  .container-dashboard{
    padding-left: calc(var(--padding-side-overall-lr)*1.6) !important;
    padding-right: calc(var(--padding-side-overall-r)*1.6) !important;
    padding-top: calc(var(--padding-side-overall-tb)*1.6) !important;
    padding-bottom: calc(var(--padding-side-overall-b)*1.6) !important;
  }
}
@media (min-width: 2000px) {
  .container-dashboard{
    padding-left: calc(var(--padding-side-overall-lr)*1.7) !important;
    padding-right: calc(var(--padding-side-overall-r)*1.7) !important;
    padding-top: calc(var(--padding-side-overall-tb)*1.7) !important;
    padding-bottom: calc(var(--padding-side-overall-b)*1.7) !important;
  }
}

/* @media (min-width: 375px) {}
@media (min-width: 450px) {}
@media (min-width: 560px) {}
@media (min-width: 768px) {}
@media (min-width: 992px) {}
@media (min-width: 1200px) {}
@media (min-width: 1400px) {}
@media (min-width: 1800px) {} */
.popup-onboard{
  position:absolute;
  height: 100vh;
  width: 100%;
  z-index: 1000000000000000000000000 !important;
  background-color: rgba(0, 0, 0, 0.715);
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.onboard-screen{
  padding: 20px 30px;
  min-width: 300px;
  width: 30%;
  position: relative;
  background-color: #02012d;
  border-radius: 10px;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Clash Display Medium';
  color: rgba(255, 255, 255, 0.7);
}
.onboard-screen h1{
  position: relative;
  font-size: 4em !important;
  text-align: center;
}
.onboard-close-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #02012d;
  position: absolute;
  top: 0;
  color: #fff;
  font-size: 1em !important;
  /* border: 7px solid #00000000 !important; */
  border: none;
  right: 0;
  /* transform: translate(9%,-9%); */
  cursor: pointer !important;
}
.btns-onboard{
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
.btns-onboard button{
  padding: 5px 10px;
  margin-left: 10px !important;
  border: none;
  border-radius: 5px;

}
