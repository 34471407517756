.waitlist-body {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 0rem 2rem;
  margin-top: -5%;
}

.waitlist-title {
  font-size: 1.5rem !important;
  text-align: center;
}

.waitlist-msg {
  border: 1px solid white;
  border-radius: 0.6rem;
  color: white;
  padding: 1.5rem !important;
  font-family: "Clash Display Medium";
  text-align: center;
  font-size: 0.9rem;
}

@media (min-width: 1200px) {
  .waitlist-body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0rem 10rem !important;
    margin-top: -5%;
  }

  .waitlist-title {
    font-size: 2.5rem !important;
    text-align: center !important;
  }

  .waitlist-msg {
    border: 1px solid white !important;
    border-radius: 0.6rem !important;
    color: white !important;
    padding: 5rem 5rem !important;
    font-size: 1.5rem !important;
    font-family: "Clash Display Medium" !important;
    text-align: center !important;
  }
}
