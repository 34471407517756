.platforms-checkbox-container {
  position: relative;
  margin: 1em;
}

.platforms-checkbox {
  background-color: #303030;
  padding: 0.5em 2em 0.5em 0.5em;
  border-radius: 10em;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.platforms-checkbox:hover{
  background:linear-gradient(to bottom, #4d4dff 0%, #02012d 100%) !important;
}

.platforms-checkbox img {
  width: 2em;
  border-radius: 100%;
}

.pl-checkboxes {
  position: absolute;
  right: 5%;
  top: -5%;
  width: 1.2rem;
  height: 1.2rem;
  background: #585858;
  border-radius: 1em;
  border: 0.125em solid white;
}

.pl-checkboxes.check {
  position: absolute;
  right: 5%;
  top: -5%;
  width: 1.2rem;
  height: 1.2rem;
  background: #009718;
  border-radius: 1em;
  border: 0.125em solid #d9d9d9;

}

.platforms-checkbox:hover > .hover-message-alt {
  display: block;
}

.hover-message-alt {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 115%);
  text-align: center;
  background-color: #02012d;
  color: #d8d8d8;
  font-size: 12px;
  padding: 0.8em;
  border-radius: 5px;
  display: none;
  transition: all 0.5s;
  font-family: "Clash Display Medium";
  width: 22em;
  z-index: 1000;
}

.hover-message-alt::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #02012d;
  position: absolute;
  top: -5px;
  font-size: 25px;
  left: 50%;
}

@media (min-width: 1200px) {
  .platforms-checkbox img {
    width: 3em;
  }

  .platforms-checkbox {
    padding: 0.5em 3em 0.5em 0.5em;
  }
  .platforms-checkbox-container {
    margin: 1.5em;
  }
}
