.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.dropdown-menu {
  background-color: #02012d !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* .dropdown-menu-right{
  display: flex !important;
  flex-direction: column !important;
} */
.drop {
  display: flex !important;
  flex-direction: column !important;
  justify-content: end;
  align-items: end;
}
.drop button {
  width: fit-content;
  margin-bottom: 0 !important;
}
.dropdown-toggle::after {
  display: none !important;
}

button#pagination-first-page {
  display: none;
}
button#pagination-last-page {
  display: none;
}
.notifications .container {
  right: 0 !important;
  z-index: 10;
  min-width: 300px !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.sc-bZHTEL {
  color: white;
}
.ecuLIP {
  color: white !important;
}

.fxlXTz svg {
  fill: white;
  color: white !important;
}
.fbJzPF {
  fill: white !important;
}

.container1 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
}

/* Hide the browser's default checkbox */
.container1 input {
  position: absolute;
  opacity: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 0.3px solid white;
}

/* On mouse-over, add a grey background color */
.container1:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the checkbox is checked, add a blue background */
.container1 input:checked ~ .checkmark {
  background-color: #2196f3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container1 input:disabled ~ .checkmark {
  background-color: gray;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container1 input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container1 .checkmark:after {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.css-0 {
  font-family: "Clash Display Medium";
}
.box select {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.edit-icon-profile {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 25px;
  visibility: hidden;
}
.asset-profile-icon:hover > .edit-icon-profile {
  visibility: visible;
}
.loading-icon-profile {
  visibility: visible !important;
  background-color: white !important;
  border-radius: 25px !important;
}

.inputWithButton input {
  width: 100%;
  height: 30px;
  padding-right: 20px;
}
.inputWithButton input::placeholder {
  color: white;
  font-size: 15px;
}
.edit-icon-disable {
  display: none !important;
  cursor: pointer;
}
.asset-profile-text:hover > .text-cover .edit-icon-disable {
  display: block !important;
}
.inputWithButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  font-size: 15px !important;
  border-bottom: 1px solid #ffeefa;
}
.inputWithButton input {
  width: 100%;
  /* height: 50px; */
  padding-right: 20px;
  background-color: transparent;
  border: none;
  color: white;
}
.inputWithButton button {
  border: none;
  background-color: transparent;
}
.inputWithButton input::placeholder {
  color: white;
  font-size: 10px;
}

.Toastify__toast-theme--dark{
  background-color: #100f24 !important;
  display: flex;
  align-items: center;
}

.Toastify__toast-theme--dark {
  display: flex;
  align-items: center;
}

.Toastify__progress-bar {
  height: 2px !important;
}
