.main-signup {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  overflow-x: hidden;
  /* background: linear-gradient(to right, #b92b27, #1565c0); */
  background-color: #02012d !important;
}
.google-signup {
  display: flex;
  align-items: center;
  justify-content: center;
}
.google-signup button {
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  background-color: #191919 !important;
  border: 1px solid #3498db !important;
}
.google-signup button:hover {
  background-color: #fff !important;
}
.google-signup button div {
  margin: 0 !important;
  border-radius: 50% !important;
  height: 50px !important;
  width: 50px !important;
  background: transparent !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
}
.google-signup button span {
  display: none !important;
}
.-hv {
  height: 100vh !important;
}
.cont {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
}
.cont-data {
  max-width: 600px;
}
.input-group-text {
  background-color: transparent !important;
  border-left: none;
  height: 100%;
  border: 1px solid #3498db !important;
  border-radius: 0px 15px 15px 0px !important;
  color: gray !important;
}
a {
  text-decoration: none !important;
}
.form-check-input {
  margin-right: 8px !important;
}
.box {
  padding: 40px;
  position: relative;
  justify-content: center;
  background: #191919;
  border-radius: 20px;
  transition: 0.25s;
  align-items: center;
}
.box-header {
  font-size: 15px;
  /* background: linear-gradient(to right, #b92b27, #1565c0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.box-header-text {
  font-size: 25px;
}
.box-header hr {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
}
.box-data {
  margin-top: 7%;
  max-width: 70% !important;
}

.box input[name="email"],
.box input[type="password"] {
  border: 0;
  background: none;
  font-family: "Clash Display Medium";
  display: block;
  margin-bottom: 20px;
  border-bottom: 1px solid #3498db;
  padding: 10px 10px;
  width: 100%;
  outline: none;
  color: white;
  transition: 0.25s;
}
.border-right {
  border-right: none !important;
}
.box h1 {
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}

.box input[name="email"]:focus,
.box input[type="password"]:focus {
  border: 1px solid linear-gradient(to right, red, purple);
}
.box Label {
  color: gray;
  margin-top: 10px;
  font-family: "Clash Display Medium";
}
.logo {
  position: absolute;
  margin: 30px;
}
.error {
  color: red;
  font-size: 12px !important;
  font-family: "Clash Display Light";
  margin: 5px;
  animation: shake 0.5s;
}
@keyframes shake {
  25% {
    transform: translate(4px);
  }
  50% {
    transform: translate(-4px);
  }
  75% {
    transform: translate(4px);
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
.signup {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  text-align: center;
  padding-top: 15px;
  font-family: "Clash Display Medium";
}
.signup a {
  text-decoration: none;
}
.button-submit .button {
  width: 230px;
}
.button-submit {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button {
  margin-top: 10px;
  border: 1px solid gray;
  color: white;
  text-transform: uppercase;
  padding: 6px;
  border-radius: 5px;
  font-family: "Clash Display Medium";
}
.button:hover {
  background-color: transparent;
  border: 1px solid white;
}
.forgot-pass {
  position: relative;
  text-align: right;
  margin-top: 15px;
  color: gray;
  margin-bottom: 5px;
  text-decoration: none;
  font-family: "Clash Display Medium";
}
.forgot-pass Link {
  text-decoration: none;
}
.profile-input {
  opacity: 0;
  position: absolute;
  height: 200px;
  width: 200px;
  z-index: 100;
}
.term-privacy {
  color: white;
  font-size: 15px;
  font-family: "Clash Display Light";
  text-align: justify;
  opacity: 0.8;
}
.close {
  color: #fff;
  opacity: 1;
}
