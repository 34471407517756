.asset-header {
  margin-top: 30px;
}
.asset-header_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 41.9471px;
  line-height: 63px;
}
.assest-haeder_drop {
  background-color: #f2efef !important;
  color: #898889 !important;
  width: 292px !important;
  height: 49px !important;
  font-family: "Inter" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 17.6095px !important;
  line-height: 26px !important;
  border-radius: 20px !important;
  border: none;
}
.text-dropdown {
  left: 0 !important;
}
.multiple-input {
  width: 311px;
  height: 49px;
  background: #f2efef;
  border-radius: 20px;
  text-align: center;
  border: none;
}
/* input[type="file"] {
  display: none;
} */

.custom-file-upload {
  width: 311px;
  height: 49px;
  border: none;
  background: #f2efef;
  display: flex;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 20px;
  text-align: center;
  color: #898889;
  align-items: center;
  justify-content: center;
}
.custom-file-upload span {
  /* position: absolute; */
  /* margin-top: 10px !important; */
}
.multiple-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 30.9652px;
  line-height: 46px;
}
.platform-container {
  width: 100%;
  /* height: 309px; */
  background: #f6f4f4;
  box-shadow: 0px 5.2732px 5.2732px rgba(0, 0, 0, 0.25);
  border-radius: 26.366px;
}

.platform-logo {
  width: 75.85px;
  height: 70px;
  border-radius: 5.57993px;
}
.platform-col {
  justify-content: center;
  align-content: center;
  align-items: center;
  height: 100% !important;
  padding: 30px 0px;
}
.platform-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 19.1076px;
  line-height: 29px;
  /* or 150% */

  text-align: center;
}
.round {
  position: relative;
  margin-right: 10px;
  margin-top: 4px;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 5px;
  left: 5px;
  opacity: 0;
  position: absolute;
  top: 5px;
  transform: rotate(-45deg);
  width: 10px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
