.app-container-teaser {
  background-color: #070414;
  width: 100vw;
  height: 100vh;
  color: #fff;
}

.app-container-teaser video {
  width: 100%;
  height: 99%;
}

.input-cont-teaser {
  width: fit-content;
  padding: 3em 3em 1.5em 3em;
  margin: auto;
}

.output-cont-teaser {
  margin: auto;
  padding: 2em 0em;
  text-align: center;
}

.player-header-teaser {
  font-family: "Clash Display SemiBold";
  font-size: 4.5vw;
  text-align: left;
}

.footer-subscribe-form-teaser {
  margin: auto;
  margin-top: 3em !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2em;
  border-bottom: 0.1em solid #422bff;
  width: 100%;
}

.footer-subscribe-form-teaser form {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-subscribe-button-teaser {
  font-family: "Clash Display Bold";
  background: linear-gradient(180deg, #432bff 0%, #6534ff 100%);
  font-size: 1rem;
  padding: 0.8em 1em;
  color: white !important;
  border: 2px solid #6534ff;
  letter-spacing: 0.025vw;
}

.footer-subscribe-button-teaser:hover {
  background: transparent;
  border: 2px solid white;
  transition-delay: 0.1s;
}

.teaser-button {
  white-space: nowrap;
  border-radius: 0.6rem;
}

.footer-subscribe-text-teaser {
  background: transparent;
  border: none;
  color: #fff;
  font-family: Clash Display Light;
  font-size: 1.1rem;
  margin-top: 0 !important;
  width: 65%;
  outline: none;
}

.footer-subscribe-text-teaser::placeholder {
  color: #fff;
  opacity: 1;
}

.player-input-teaser {
  margin-top: 2em;
}
