.upload-container {
  /* padding: 3em 15em 3rem 5rem; */
  /* height: 100%; */
  margin-top: 1em !important;
}

.upload-container-first {
  padding-left: 1em;
}

.upload-title {
  font-family: "Clash Display Bold";
  /* font-size: 3rem; */
  color: white;
  word-spacing: 0.2em;
}

.upload-contents {
  width: 100%;
  margin-top: 2em;
}

.page-indicater {
  /* position: fixed; */
  /* top: 55%;
  right: 5%;
  transform: translateY(-50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page-indicater-item {
  background-color: #373737;
  padding: 0.5em 1em;
  /* font-size: 1.5rem; */
  font-family: "Clash Display SemiBold";
  color: #8b8b8b;
  border-radius: 0.6em;
}

.page-indicater-item.active {
  background-color: #898989 !important;
  border: 0.15em solid white;
  color: white;
}

.page-indicater-item.completed {
  background-color: #282760 !important;
  border: 0.15em solid white;
  color: white;
}

.page-indicater-line {
  height: 8em;
  width: 0.15em;
  background: white;
  /* margin: auto; */
}

.uc-items-title {
  /* font-size: 1em; */
  color: #b6b6b6;
  font-family: "Clash Display Medium";
  margin-bottom: 0.5em;
  word-spacing: 0.05em;
}

.uc-items-subtitle {
  /* font-size: 0.9em; */
  color: #b6b6b6;
  font-family: "Clash Display Medium";
}

.uc-items-title-thin {
  /* font-size: 1em; */
  color: #b6b6b6;
  font-family: "Clash Display Regular";
  word-spacing: 0.05em;
}

.uc-items-subtitle-thin {
  /* font-size: 0.9em; */
  color: #b6b6b6;
  font-family: "Clash Display Regular";
}

.uc-items-link {
  color: #615eed;
}

.items-img-m img {
  width: 3.5em;
}

.uc-items-input {
  background: #2e2c6a;
  outline: none;
  padding: 0.6em;
  color: white;
  font-family: "Clash Display SemiBold";
  border-radius: 0.6em;
  width: 100%;
  border: 0.1em solid #2e2c6a;
  /* font-size: 1.2rem; */
}

.uc-items-input:focus {
  border: 0.1em solid #5553da;
}

.uc-buttons {
  background-color: #282760;
  /* font-size: 1rem; */
  color: white;
  font-family: "Clash Display SemiBold";
  border: 0.1em solid #282760;
  border-radius: 0.6em;
  /* min-width: 15em; */
  word-spacing: 0.2em;
}

.uc-buttons:hover,
.uc-buttons:focus,
.uc-buttons:active {
  border: 0.1em solid #5553da;
  background-color: #2e2c6a;
  color: white;
}

.uc-buttons-container {
  width: 100%;
}

.uc-buttons-items {
  width: 100%;
  flex-direction: column;
  margin-top: 2em;
}

.uc-buttons-items > .kb-buttons-box {
  width: 100%;
}

.uc-buttons-items > div > button {
  min-width: 100%;
  width: auto;
  white-space: nowrap;
}

.swal-close:hover {
  color: #615eed;
}

#close-button-id,
#upload-title-height1,
#upload-title-height2,
#upload-title-height3 {
  height: 3rem;
  display: flex;
  align-items: center;
}

@media (min-width: 425px) {
  .upload-container {
    margin-top: 2em !important;
  }
  .upload-container-first {
    padding-left: 2em;
  }
  .uc-buttons-items {
    flex-direction: row;
    margin-top: 0em;
  }
  .uc-buttons-items > .kb-buttons-box:first-child {
    margin-right: 0.5em;
  }
  .uc-buttons-items > .kb-buttons-box:last-child {
    margin-left: 0.5em;
  }
}

@media (min-width: 576px) {
  .upload-container {
    margin-top: 2em !important;
  }
  .upload-container-first {
    padding-left: 3em;
  }
}

@media (min-width: 992px) {
  .uc-buttons-items {
    float: right;
    width: 50%;
    justify-content: space-between;
  }

  .uc-buttons-items > .kb-buttons-box {
    width: 47%;
  }
  .uc-buttons-items > .kb-buttons-box:first-child {
    margin-right: 0em;
  }
  .uc-buttons-items > .kb-buttons-box:last-child {
    margin-left: 0em;
  }
}
