.payment-modal {
  width: auto;
  height: auto;
  background-color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 3em 4em;
}

.pay-with-card-btn {
  width: 100% !important;
  border-radius: 2rem !important;
  padding: 1.5rem 2rem !important;
  margin-top: -0.5rem !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  color: white !important;
  font-family: "Clash Display SemiBold" !important;
  margin-left: 1rem !important;
  font-size: 1.125rem !important;
}

.promo-code-input > input::placeholder {
  font-family: "Clash Display Medium";
}
