.breadcrumb .active {
  background-color: transparent !important;
}
.manage-asset-input::placeholder {
  font-size: 18px !important;
}
.manage-asset-input {
  width: 100%;
  height: 30px !important;
  border: 1px solid transparent;
  border-radius: 8px !important;
  padding: 7.5px;
  border: 1px solid #898989;
  font-size: 18px !important;
  background-color: #191b30 !important;
}
.dwPlXY {
  padding: 10px;
}
.rdt_TableHeader {
  width: max-content;
  right: 0;
  background-color: transparent !important;
  position: absolute !important;
  bottom: 0;
  transform: translateY(130%);
}
.beUiVL {
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.594) !important;
}
.kNxEtY {
  display: none;
}
.rdt_Table,
.rdt_Pagination {
  /* background: linear-gradient(180deg, #1E1D47 18.75%, rgba(0, 0, 0, 0) 100%); */
  /* backdrop-filter: blur(16.0562px); */
  background-color: transparent !important;
  color: white;
}
.sc-eDWCr,
.bzRnkJ div,
.sc-bYMpWt {
  color: white;
}
.sc-gikAfH svg {
  fill: white;
}
.bBpdsi,
.gMliOo svg {
  color: white !important;
}
.bzRnkJ,
.rdt_TableHeadRow {
  background: transparent !important;
}
.rdt_TableBody {
  background-color: transparent !important;
}
.rdt_TableRow {
  background: transparent !important;
  box-shadow: 0px 5.2732px 5.2732px rgba(0, 0, 0, 0.25) !important;
  position: relative;
  align-items: baseline !important;
}
.hPQIua {
  position: absolute;
  right: 0;
  height: 100%;
}
.rdt_TableHead {
  /* margin-bottom: 20px !important; */
  position: relative !important;
}
.rdt_Pagination {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 1em !important;
  padding-top: 2em !important;
  padding-bottom: 2em !important;
}
.fa-comment-alt {
  position: relative;
}

.fa-comment-alt::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 8px;
  height: 8px;
  color: red;
  background-color: red;
  border-radius: 50%;
  transform: translate(50%, -50%);
}
/* .gMnBPf{
    position: absolute !important;
    right: 20px !important;
    height: 100% !important;
} */
.manage-assets {
  margin: 0;
  padding: 0;
}
.input-icons i {
  position: absolute;
}

.input-icons {
  width: 100%;
}

.input-icon {
  padding: 13px;
  color: white;
  min-width: 50px;
  text-align: center;
}
.input-icon-after {
  padding: 13px;
  color: white;
  min-width: 50px;
  text-align: center;
  position: absolute;
  right: 0;
  transform: translateX(-100%);
}

.input-field {
  width: 100%;
  padding-left: 50px;
}
.btn-create {
  padding: 10px;
  background-color: #f2efef;
  border-radius: 1rem;
  border: 1px solid #ccc;
  font-family: "Inter Bold";
}
.tabs-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 2%;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 20px 30px;
}
.tabs-container button {
  background-color: #282760;
  border: none;
  color: white;
  font-family: "Inter Bold";
  font-size: 0.8rem;
  padding: 5px 10px;
  cursor: pointer;
  margin: 10px;
  border-radius: 0.5rem;
}
.active-filter {
  background-color: #f2efef !important;
  color: black !important;
}
.kNUuTe {
  left: -100% !important;
}
.action-toggle {
  color: white !important;
}
.jaZsat {
  color: white !important;
}
.modal-content {
  background-color: #282760 !important;
}
.modal-title {
  color: white !important;
}
.delete-button {
  background: #181739 !important;
  border: 1px solid #898989 !important;
  border-radius: 8px;
  gap: 4px;
  padding: 4px 8px;
}
.pay-button {
  background: #4e4bdc;
  border-radius: 8px;
  gap: 4px;
}
.tables {
  backdrop-filter: blur(16.0562px);
}
.dwPlXY:not(:last-of-type) {
  border: 1px sollid white !important;
}


.container-line::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0),
    #0057ff 50%,
    rgba(255, 255, 255, 0) 100%
  );
}
.container-line{
  position: relative;
  width: 100%;
}
