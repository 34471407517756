.card-recents {
  width: 228.87px;
  height: 345.31px;
  background: #1e1d47 !important;
  border: 1px solid #a68567;
  border-radius: 18.8485px;
}
.recents-container {
  position: relative !important;
  margin-top: -3em;
  padding: 0em 1.8em;
  /* z-index: 1; */
}
.swiper-slide {
  display: flex;
  justify-content: center;
}
.swiper-button-prev,
.swiper-button-next {
  background: linear-gradient(
    180deg,
    rgba(108, 56, 255, 0.09) 0%,
    rgba(139, 98, 255, 0.09) 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.50704px);
}
.image-card {
  position: relative !important;
  background: #19192d !important;
  width: 90%;
  height: 13rem;
  margin: 5%;
  border-radius: 5%;
}
.card-image {
  position: relative;
  height: 100%;
  object-fit: contain;
  width: 100% !important;
  overflow-y: hidden;
  justify-content: center;
  border-radius: 5%;
}
.action-container {
  position: absolute;
  top: 7%;
  left: 10%;
  z-index: 11;
  padding: 0% 1%;
  height: 8%;
  border-radius: 15.6196px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
}
.under-review-bg {
  background: rgba(102, 80, 38, 0.6);
}
.under-review-action-icon path,
.under-review-text-color {
  color: #ff7a00;
  stroke: #ff7a00;
}
.completed-action-icon path,
.completed-text-color {
  color: #6beba4;
  stroke: #6beba4;
}
.pending-action-icon path,
.pending-text-color {
  color: red;
  stroke: red;
}
.under-review-border {
  border: 1px solid #a68567;
  background: rgba(102, 80, 38, 0.6);
}
.completed-border {
  border: 1px solid green;
  background: rgba(38, 102, 99, 0.6);
}
.action-needed-border {
  border: 1px solid red;
}
.action-needed-bg {
  background: rgba(102, 38, 38, 0.6);
}
.completed-bg {
  background: rgba(38, 102, 99, 0.6);
}

.action-container span {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 11.71011px;
  line-height: 140%;
  margin-left: 3px;
}
.action-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 90%;
  background-color: white;
  border-radius: 50%;
}
.card-title {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 500;
  font-size: 18.71011px;
  line-height: 140%;
  color: #ffffff;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 6%;
}
.asset-progress {
  position: relative;
  width: 70%;
  height: 5px;
  background: rgb(94 92 230 / 20%);
  border-radius: 2.5px;
  margin-bottom: 5%;
}
.progress-bar1 {
  position: absolute;
  left: 0;
  /* z-index: 10; */
  height: 5px;
  background: #5e5ce6;
  border-radius: 2.5px;
  margin-bottom: 5%;
}
.progress-bar-ball {
  position: absolute;
  overflow: visible;
  right: 1px;
  transform: translateY(-30%);
  box-shadow: -1.75726px 0px 3.51451px #facca1;
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
}
.progress-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.button-card-cancel {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  align-items: center;
  padding: 0px;
  gap: 21.37px;
  position: absolute;
  width: 70%;
  height: 10%;
  background: #282760;
  box-shadow: 0px 63.726px 25.1314px rgba(0, 0, 0, 0.03),
    0px 35.902px 21.5412px rgba(0, 0, 0, 0.1),
    0px 16.1559px 16.1559px rgba(0, 0, 0, 0.17),
    0px 3.5902px 8.97549px rgba(0, 0, 0, 0.19), 0px 0px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8.70463px;
  color: white;
}
.button-card {
  display: flex;
  justify-content: center;
}
.overview-container {
  overflow: hidden;
  position: relative;
  padding: 5%;
  width: 90%;
  min-height: 85%;
  background: #1e1d47;
  box-shadow: -16px 113px 46px rgba(9, 8, 43, 0.01),
    -9px 64px 39px rgba(9, 8, 43, 0.05), -4px 28px 29px rgba(9, 8, 43, 0.09),
    -1px 7px 16px rgba(9, 8, 43, 0.1), 0px 0px 0px rgba(9, 8, 43, 0.1);
  border-radius: 5%;
}
.overview-container::before {
  position: absolute;
  top: 15%;
  left: 0%;
  content: "";
  height: 70%;
  width: 1%;
  background: linear-gradient(
    0deg,
    rgba(71, 118, 240, 0.06) 0%,
    #0057ff 50%,
    rgba(74, 71, 240, 0.06) 99.48%
  );
}
.overview-container::after {
  position: absolute;
  top: 15%;
  right: 0;
  content: "";
  height: 70%;
  width: 1%;
  background: linear-gradient(
    0deg,
    rgba(71, 118, 240, 0.06) 0%,
    #0057ff 50%,
    rgba(74, 71, 240, 0.06) 99.48%
  );
}
.under-review {
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  position: relative;
}
.action-needed-icon {
  color: red !important;
}
.pending-icon {
  color: orange !important;
}
.completed-icon {
  color: green !important;
}
.under-review-icon {
  width: 18px;
  height: 18px;
  background-color: white !important;
  border-radius: 50%;
  color: violet;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}
.under-review-text {
  font-family: "Clash Display";
  width: 80%;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 116%;
  display: flex;
  color: violet;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 0;
}
.under-review-percentage {
  font-family: "Clash Display";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 116%;
  display: flex;
  align-items: center;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.under-review::after {
  content: "";
  position: absolute;
  bottom: -10%;
  width: 30%;
  height: 1px;
  margin-top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(
    270deg,
    rgba(71, 118, 240, 0.06) 0%,
    #0057ff 50%,
    rgba(74, 71, 240, 0.06) 99.48%
  );
}
.under-review:last-child ::after {
  display: none !important;
}
.data-table {
  /* background: linear-gradient(152.97deg, #1e1d47 18.75%, rgba(0, 0, 0, 0) 100%); */
  background:transparent !important;
  backdrop-filter: blur(16.0562px);
}
.jaZsat option {
  color: white;
}
.feGxUP {
  color: white;
}
.BafWS {
  background-color: transparent !important;
}
.Fgnmg,
.cvQOBp,
.bhQwst {
  background-color: transparent !important;
  display: hidden !important;
  color: transparent !important;
}
.feGxUP {
  color: white;
}
.rdt_TableCol {
  color: white !important;
}
.swiper-button-next {
  position: absolute !important;
  margin-left: -100%;
}
.swiper-button-next,
.swiper-button-prev {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 5.01px;
  width: 45.13px !important;
  height: 45.13px !important;
  background: linear-gradient(
    180deg,
    rgba(108, 56, 255, 0.3) 0%,
    rgba(139, 98, 255, 0.3) 100%
  );
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25),
    0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2.50704px);
  border-radius: 50%;
  flex: none;
  order: 1;
  flex-grow: 0;
}
.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px !important;
  color: #ffffff;
}
.swiper-button-prev {
  margin-right: 50px;
}
.tam {
  background: rgba(9, 32, 57, 0.7) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(17.5px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
}
.tam .image-container .image-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(17.5px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  border-radius: 10px !important;
  height: 93%;
  cursor: pointer;
}
.tam .image-container .action-container {
  background: rgba(243, 246, 250, 0.1) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(20px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  border-radius: 5px !important;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.tam .image-container .action-container .action-icon-container {
  background-color: transparent;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
.tam .image-container .action-container span {
  visibility: hidden;
}
.tam h5 {
  background: rgba(243, 246, 250, 0.1) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(20px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  border-radius: 5px !important;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: transparent !important;
  width: 80% !important;
}
.tam .progress-container {
  background: rgba(243, 246, 250, 0.1) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(20px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  border-radius: 5px !important;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: transparent !important;
  width: 60% !important;
  margin-left: 6% !important;
  margin-bottom: 10px !important;
}

.tam .progress-container::after {
  content: "";
  background: rgba(243, 246, 250, 0.1) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(20px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  border-radius: 5px !important;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: transparent !important;
  width: 60% !important;
  height: 100% !important;
  margin-left: 6% !important;
}
.tam .progress-container span {
  visibility: hidden;
}
.tam .button-card button {
  background: rgba(243, 246, 250, 0.1) !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(20px) !important;
  -webkit-backdrop-filter: blur(20px) !important;
  border-radius: 5px !important;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: white !important;
  width: 50% !important;
  margin-left: 6% !important;
  margin-bottom: 10px !important;
}
.draft path {
  stroke: gray;
}
.in_progress path {
  stroke: blue;
}
.under_review path {
  stroke: violet;
}
.dropdown-status button {
  background: #191b30;
  border: 1px solid #898989;
  border-radius: 8px;
}
.dropdown-status button:hover {
  background: #191b30;
  border: 1px solid #898989;
  border-radius: 8px;
}
.sc-hLBbgP {
  align-items: center;
  display: flex;
  justify-content: center;
}
.dropdown-item {
  color: white !important;
}
.active-filter {
  color: black !important;
}
.div-empty {
  position: absolute;
  bottom: 0;
  background: linear-gradient(
    180deg,
    rgba(56, 114, 201, 0.18) 27.96%,
    rgba(56, 62, 201, 0) 76.79%
  );
  transform: rotate(179.67deg);
  width: 100%;
  height: 20%;
  left: 0;
  border-end-start-radius: 5%;
}
@media (max-width: 640px) {
  .action-container {
    left: 20%;
  }
}
