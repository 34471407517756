@font-face {
  font-family: Clash Display Bold;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Bold.ttf");
}

@font-face {
  font-family: Clash Display Light;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Light.ttf");
}

@font-face {
  font-family: Clash Display ExtraLight;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Extralight.ttf");
}

@font-face {
  font-family: Clash Display Medium;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Medium.ttf");
}

@font-face {
  font-family: Clash Display SemiBold;
  src: url("https://xrv-xrc.s3.ap-south-1.amazonaws.com/XRC_Homepage/homepage_contents/ClashDisplay-Semibold.ttf");
}

body {
  margin: 0;
  font-family: "Clash Display Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Clash Display Medium";
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.container-sidebar {
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
  text-decoration: none !important;
}

main {
  width: 100%;
  margin-left: 265px;
  min-height: 100vh;
  width: calc(100% - 265px);
  background: #070414;
}

.img-sidebar {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.sidebar {
  /* position: relative; */
  background: #02012d;
  color: #fff;
  height: 100vh;
  position: fixed;
  min-height: 100vh;
  width: 250px;
  /* transition: all 0.5s; */
}

.top_section {
  display: flex;
  align-items: center;
  padding: 20px 15px;
}

.logo-sidebar {
  font-size: 30px;
  height: 20px;
}

.bars {
  display: flex;
  font-size: 20px;
  /* margin-left: 10px; */
}

.link {
  padding-left: 30px;
  display: flex;
  color: #a7a7a7;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: "Clash Display Light" !important;
}

.link:hover {
  width: fit-content;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: rgba(255, 255, 255, 0.18);
  color: white;
}

.link .arrow-icon svg {
  display: none;
}

.active {
  width: fit-content;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  background: rgba(255, 255, 255, 0.18);
  color: #fff;
}

.active .arrow-icon svg {
  display: block;
}

.arrow-icon {
  float: right;
}
.active .link_text{
  font-family: "Clash Display Medium" !important;
}
.icon,
.link_text {
  font-family: "Clash Display Light";
  font-size: 18px;
}

.link:last-child {
  position: absolute;
  bottom: 0px;
  align-items: center;
}

.modal-header-custom {
  justify-content: center;
  align-items: center;
  justify-items: center;
  width: 100% !important;
  position: absolute;
}

.sidebar-profile-container::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
}

.sidebar-profile-container {
  padding-top: 20px;
  position: relative;
  width: 80%;
  margin-left: 10%;
}

.profile-button {
  background: #4e4bde;
  border-radius: 4px;
  color: white;
  padding: 2% 5%;
  outline: transparent;
  border: none;
  margin-top: 10px;
}

.profile-button:hover {
  background: white;
  color: #4e4bde;
}

.tag-container-sidebar {
  position: relative;

  width: 50%;
  margin-left: 25%;
  margin-top: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-tag {
  background: url("./assets/Union.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 8px;
}

.sidebar-tag button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 3px 5px;
  background: #0c0b2f;
  border-radius: 11px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-bottom: 5px;
  font-size: 8px;
  color: white;
}

.items .card {
  margin-bottom: 0;
}

.notifications .container {
  width: 300px !important;
  border-radius: 10px;
}

.notifications .container .header {
  font-family: "Clash Display Medium";
  font-size: 18px;
}

.hover-messaage {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 110%);
  text-align: center;
  background-color: #02012d;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  display: none;
  transition: all 0.5s;
}

.hover-messaage-icon {
  position: absolute;
  bottom: 0;
  left: 0%;
  transform: translate(-50%, 120%);
  text-align: center;
  background-color: #02012d;
  color: #fff;
  font-size: 12px;
  padding: 10px;
  border-radius: 5px;
  display: none;
  transition: all 0.5s;
}

.hover-messaage::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #02012d;
  position: absolute;
  top: -5px;
  font-size: 25px;
  left: 50%;
}
/* .hover-messaage-icon::after{
  content: "";
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 15px solid #02012d;
  position: absolute;
  top: -5px;
  font-size: 10px;
  left: 72%;
} */

.button-download-div:hover > .hover-messaage {
  display: block;
}
.button-download-div:hover > .hover-messaage-icon {
  display: block;
}
select:focus-visible{
  border: none;
}
select:focus-within{
  border: none;
}
option:focus-visible{
  border: none;
}
.swal2-popup {
  background-color: #02012d !important;
  border: 1px solid rgb(67, 67, 67) !important;
}

.swal2-title {
  font-family: "Clash Display Medium";
}

.swal2-html-container,
.swal2-actions {
  font-family: "Clash Display Medium";
}

.gZPXCo,
.kzWFIh {
  opacity: 1 !important;
}

.RSPBprogressBar {
  width: 90%;
}

.RSPBprogressBar > div:first-child {
  left: 10% !important;
}

.RSPBprogressBar > div:nth-child(2) {
  left: 30% !important;
}

.text-progress {
  position: absolute;
  color: white;
  bottom: 0;
  width: 100px;
  transform: translateY(150%);
  display: none;
  text-align: center;
}

.RSPBprogressBar > div:hover > .text-progress {
  display: block;
}

.nc-bell-button svg {
  color: white;
}

/* RESPONSIVE FONT SIZES */

.fs-xxs {
  font-size: 0.4261157914rem !important;
}

.fs-xs {
  font-size: 0.4738324949rem !important;
}

.fs-sm {
  font-size: 0.5685990529rem !important;
}

.fs-m {
  font-size: 0.7108259061rem !important;
}

.fs-lg {
  font-size: 0.9476650898rem !important;
}

.fs-xl {
  font-size: 1.4216518123rem !important;
}

.fs-xxl {
  font-size: 2.36916346rem !important;
}

@media (min-width: 425px) {
  .fs-xxs {
    font-size: 0.4734619904rem !important;
  }

  .fs-xs {
    font-size: 0.5264805499rem !important;
  }

  .fs-sm {
    font-size: 0.6317767254rem !important;
  }

  .fs-m {
    font-size: 0.7898065623rem !important;
  }

  .fs-lg {
    font-size: 1.0529610998rem !important;
  }

  .fs-xl {
    font-size: 1.5796131247rem !important;
  }

  .fs-xxl {
    font-size: 2.6324038444rem !important;
  }
}

@media (min-width: 576px) {
  .fs-xxs {
    font-size: 0.4983810425rem !important;
  }

  .fs-xs {
    font-size: 0.5541900525rem !important;
  }

  .fs-sm {
    font-size: 0.6650283425rem !important;
  }

  .fs-m {
    font-size: 0.8313753288rem !important;
  }

  .fs-lg {
    font-size: 1.108380105rem !important;
  }

  .fs-xl {
    font-size: 1.662562997rem !important;
  }

  .fs-xxl {
    font-size: 2.7709503625rem !important;
  }
}

@media (min-width: 768px) {
  .fs-xxs {
    font-size: 0.52482215rem !important;
  }

  .fs-xs {
    font-size: 0.58335795rem !important;
  }

  .fs-sm {
    font-size: 0.70002973rem !important;
  }

  .fs-m {
    font-size: 0.875131925rem !important;
  }

  .fs-lg {
    font-size: 1.1667159rem !important;
  }

  .fs-xl {
    font-size: 1.75016985rem !important;
  }

  .fs-xxl {
    font-size: 2.91678975rem !important;
  }
}

@media (min-width: 992px) {
  .fs-xxs {
    font-size: 0.5526549rem !important;
  }

  .fs-xs {
    font-size: 0.614061rem !important;
  }

  .fs-sm {
    font-size: 0.7368734rem !important;
  }

  .fs-m {
    font-size: 0.9211915rem !important;
  }

  .fs-lg {
    font-size: 1.228122rem !important;
  }

  .fs-xl {
    font-size: 1.842283rem !important;
  }

  .fs-xxl {
    font-size: 3.070305rem !important;
  }
}

@media (min-width: 1200px) {
  .fs-xs {
    font-size: 0.756rem !important;
  }

  .fs-sm {
    font-size: 0.9072rem !important;
  }

  .fs-m {
    font-size: 1.134rem !important;
  }

  .fs-lg {
    font-size: 1.512rem !important;
  }

  .fs-xl {
    font-size: 2.268rem !important;
  }

  .fs-xxl {
    font-size: 3.78rem !important;
  }
}

@media (min-width: 1400px) {
  .fs-xxs {
    font-size: 0.9rem !important;
  }

  .fs-xs {
    font-size: 1rem !important;
  }

  .fs-sm {
    font-size: 1.2rem !important;
  }

  .fs-m {
    font-size: 1.5rem !important;
  }

  .fs-lg {
    font-size: 2rem !important;
  }

  .fs-xl {
    font-size: 3rem !important;
  }

  .fs-xxl {
    font-size: 5rem !important;
  }
}
.reactour__popover {
  z-index: 99999999999 !important;
  background-color: #02012d !important;
  color: white !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border: .6px solid white;
  border-radius: 15px;
}
:dir("ltr"){
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center !important;
}
 div.reactour__popover > p{
  font-family: "Clash Display Light";
  text-align: center;
}
  div.reactour__popover > div{
  /* width: 40%; */
  display: flex !important;
  margin-top:5px !important;
  align-items: center;
  justify-content: center !important;
}
div.reactour__popover > div > button > svg{
  color: white !important;
}
.reactour__close-button {
  color: white !important;
}
.reactour__mask {
  z-index: 9999999999 !important;
  opacity: .8 !important;
}
div.reactour__popover > span{
  position: absolute !important;
  bottom: 17px !important;
  left: 50% !important;
  transform: translateX(-50%);
  top: unset !important;
}

#root > div.reactour__popover > div > button:nth-child(1){
  margin-right: 50px !important;

}
@import url(https://fonts.googleapis.com/css?family=Nunito:400,700);

.body-search .two {
  background: #f6f792;
}
.body-search .three {
  background: #ea2e49;
}
.body-search .four {
  background: #333745;
}
.body-search .search {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  /* top: 50%; */
  /* transform: translateY(-50%); */
  /* width: 100%; */
  text-align: center;
  overflow: hidden;

  border-radius: 5px;
}
.body-search .search_bar {
  /* width: 280px; */
  position: relative;
  margin: 0 auto;
}
.body-search .search_bar input[type="text"] {
  width: 15px;
  background: transparent;
  transition: border 0.1s 0s,
    width 0.2s 0.1s cubic-bezier(0.225, 0.01, 0.475, 1.01),
    text-indent 0.2s 0.1s;
  padding: 20px;
  border-color: #fff;
  text-indent: 30px;
  outline: none;
  border: 0px solid #fff;
  font-size: 12px;
  color: #fff;
  border-radius: 5px;
  font-family: "Nunito", sans-serif;
}
.body-search .search_bar ::-webkit-input-placeholder {
  /* Safari, Chrome and Opera */
  color: #fff;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
}
.body-search .search_bar ::-moz-input-placeholder {
  /* Safari, Chrome and Opera */
  color: #fff;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
}
.body-search .search_bar ::-o-input-placeholder {
  /* Safari, Chrome and Opera */
  color: #fff;
  font-weight: 400;
  font-family: "Nunito", sans-serif;
}
.body-search .search_bar input[type="checkbox"] {
  display: none;
}
.body-search .search_bar input[type="checkbox"]:checked + label + input {
  width: 100%;
  border: 1px solid #4b4a4a;
  text-indent: 0px;
}
.body-search .search_bar input[type="checkbox"]:checked + label i {
  -webkit-text-stroke: 1px background-color;
  right: 0px;
  transform: translateY(-50%) translateX(50%) rotate(360deg) scale(0);
  color: #fff;
}
.body-search .search_bar input[type="checkbox"]:checked + label .last {
  /* left: 220px; */
  transform: translateY(-50%) rotate(360deg) scale(1);
  /* margin-right: 15px; */
}
.body-search .search_bar input[type="checkbox"]:checked + label p {
  top: 50%;
  transition: all 0.1s 0.45s;
  z-index: 100;
}
.body-search .search_bar input[type="checkbox"]:not(checked) + label p {
  top: -50%;
  transition: all 0.1s 0s;
}
.body-search .search_bar .last {
  transform: translateY(-50%) rotate(0deg) scale(0);
}
.body-search .search_bar i {
  position: absolute;
  font-size: 25px;
  top: 50%;
  transform: translateY(-50%) translateX(50%) rotate(0deg) scale(1);
  cursor: pointer;
  z-index: 2;
  margin: auto;
  border-radius: 4px;
  width: 56px;
  right: 50%;
  height: 54px;
  background: transparent;
  transition: right 0.1s 0.1s, transform 0.1s 0.1s, color 0.1s;
  line-height: 60px;
  color: #fff;
}
.body-search .search_bar i:hover {
  color: #fff;
}
.body-search .search_bar p {
  position: absolute;
  margin: 0;
  right: 52px;
  color: #fff;
  font-weight: 200;
  font-size: 30px;
  top: -50%;
  transform: translateY(-50%) rotate(0deg) scale(1);
}

.box-shadow:hover{
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

/* width */
::-webkit-scrollbar {
  width:5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 50px #02012d; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c6e5; 
  /* border-radius: 10px; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000; 
}

/* width */
.tables ::-webkit-scrollbar {
  height: 5px;
}

/* Track */
.tables ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 15px #02122d; 
  border-radius: 0px;
}
 
/* Handle */
.tables ::-webkit-scrollbar-thumb {
  background: white; 
  border-radius: 0px;
}

/* Handle on hover */
.tables ::-webkit-scrollbar-thumb:hover {
  background: white; 
}
.css-1knp9lb {
  max-width: 85% !important;
}
.swal2-container.swal2-center{
  z-index: 9876543219876543;
}

.btn-close-tour{
  border: 1px solid white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
#root > div.reactour__mask > svg > rect:nth-child(4){
  border: 1px dotted white;
}