.create-ticket-modal {
  background-color: #02012d;
  border: 1px solid #c4c4c4;
  height: auto;
  display: flex;
  justify-content: center;
  /* padding: 2em; */
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  border-radius: 0.6em;
  width: 65%;
}

.create-ticket-modal-cont {
  overflow-y: auto;
  max-height: 95vh;
  padding: 2em;
}

.support-subject-input {
  width: 15em;
}

select option {
  border: none !important;
  padding: 10rem !important;
  outline: none !important;
}

.desc-options-cont {
  border-bottom: 1px solid rgb(180, 180, 180);
  width: fit-content;
}

.desc-options {
  padding: 0.6em 1.2em;
  color: white;
  font-family: "Clash Display SemiBold";
  cursor: pointer;
  border-radius: 0.6em 0.6em 0rem 0rem;
  background: #13122d;
}

.desc-options.active {
  background-color: #2E2C6A;
  padding: 0.6em 1.2em;
  color: white;
  font-family: "Clash Display SemiBold";
  cursor: pointer;
}

@media (min-width: 769px) {
  .create-ticket-modal {
    top: 50%;
    left: 64%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 992px) {
  .create-ticket-modal {
    top: 55%;
    transform: translate(-50%, -50%);
  }
}

@media (min-width: 1200px) {
  .create-ticket-modal {
    /* padding: 2em 3em; */
    top: 50%;
    left: 60%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  .create-ticket-modal-cont {
    padding: 2em 3em;
  }
}

@media (min-width: 1800px) {
  .create-ticket-modal {
    /* padding: 2em 3em; */
    top: 50%;
    left: 55%;
    width: 50%;
    transform: translate(-50%, -50%);
  }
  .create-ticket-modal-cont {
    padding: 2em 3em;
  }
}
