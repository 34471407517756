@keyframes modal-icon1 {
  0% {
    background-color: green;
    color: white;
    transform: rotate(0);
  }
  100% {
    color: green;
    background-color: white;
    transform: rotate(360deg);
  }
}

@keyframes modal-icon2 {
  0% {
    background-color: red;
    color: white;
    transform: rotate(0);
  }
  100% {
    color: red;
    background-color: white;
    transform: rotate(360deg);
  }
}

.modal-icon-success {
  font-size: 60px;
  color: green;
  position: relative;
  top: -30px;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  animation: modal-icon1 1.5s;
}

.modal-icon-error {
  font-size: 60px;
  color: red;
  position: relative;
  top: -30px;
  background-color: white;
  border-radius: 50%;
  padding: 10px;
  animation: modal-icon2 1.5s;
}

.modal-custom {
  background-color: black !important;
  color: gray;
}

.waitlist-body-alt {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100vw !important;
  height: 100vh !important;
  padding: 0rem 10rem !important;
  margin-top: -5% !important;
  background-color: #02012d;
  margin-top: 0% !important;
}

.waitlist-msg-alt {
  border: 1px solid white !important;
  border-radius: 0.6rem !important;
  color: white !important;
  padding: 5rem 5rem !important;
  font-size: 1.5rem !important;
  font-family: "Clash Display Medium" !important;
  text-align: center !important;
  margin-top: 0% !important;
}
