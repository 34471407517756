.asset-view-container {
  padding: 1.5em 1.5em;
}

.thumbnail-preview-img-view {
  width: 6em;
  height: 6em;
  object-fit: cover;
  object-position: center top;
}

.user-data-group-alt {
  padding: 2em;
}

.uploaded-img-cont {
  padding: 0.3em 0.8em 0.3em 0.5em;
  border-radius: 0.2em !important;
  margin: 1em 0em;
}

.uploaded-img-preview {
  width: 2em;
  height: 2em;
  object-fit: cover;
  object-position: center top;
}

@media (min-width: 425px) {
  .uploaded-img-cont {
    margin-right: 1em;
  }
}

@media (min-width: 992px) {
  .asset-view-container {
    padding: 2em 3em;
  }
}

@media (min-width: 1200px) {
  .thumbnail-preview-img-view {
    width: 8em;
    height: 8em;
  }
}

@media (min-width: 1400px) {
  .thumbnail-preview-img-view {
    width: 10em;
    height: 10em;
  }
  .uploaded-img-cont {
    margin-right: 2em;
  }
}