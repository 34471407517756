.user-data-group {
  padding: 1em 2em 1em 1em;
}

.user-data-field {
  font-family: "Clash Display Light";
  font-size: 0.9rem;
  opacity: 0.8;
  white-space: nowrap;
}

.user-data-value {
  font-family: "Clash Display Medium";
  font-size: 1.1rem;
}

.user-data-field-alt {
  font-family: "Clash Display Light";
  font-size: 1rem;
  opacity: 0.8;
  white-space: nowrap;
}

.user-data-value-alt {
  font-family: "Clash Display SemiBold";
  font-size: 1.5rem;
}

.user-data-img-title {
  font-family: "Clash Display Medium";
}

.user-data-img-size {
  font-family: "Clash Display Medium";
  opacity: 0.8;
}
