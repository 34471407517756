.support-chat-container {
  padding: 3rem;
}

.chat-body {
  /* height: 23rem; */
  padding: 0rem;
  /* background-color: rgb(14, 14, 14); */
  /* overflow-y: scroll; */
  /* border-radius: 1rem; */
  /* align-items: flex-end; */
}

.a-box {
  position: relative;
  background-color: #0d1117;
  /* padding: 2rem; */
  border-radius: 0rem 0rem 0rem 0rem;
  color: #fff;
  width: 95%;
  margin-top: 3rem;
  /* align-self: flex-start; */
  border-radius: 1rem;
}

.a-box::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #232a51;
  position: absolute;
  top: 3%;
  font-size: 10px;
  left: -15px;
  transform: rotate(270deg) translateX(-100%);
}

.r-box {
  position: relative;
  background-color: #0d1117;
  /* padding: 2rem; */
  border-radius: 0rem 0rem 0rem 0rem;
  color: #fff;
  width: 95%;
  margin-top: 3rem;
  /* align-self: flex-end; */
  border-radius: 1rem;
}

.r-box::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #1c2229;
  position: absolute;
  top: 3%;
  font-size: 10px;
  left: -15px;
  transform: rotate(270deg) translateX(-100%);
}

.support-chat-input {
  width: 100%;
  padding: 2rem;
  outline: none;
  border: none;
  background-color: rgb(14, 14, 14);
  color: white;
  font-family: "Clash Display SemiBold";
  border-radius: 1rem;
}

.msg-info-r {
  padding: 1rem 2rem;
  background-color: #1c2229;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msg-info-a {
  padding: 1rem 2rem;
  background-color: #252b4d;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msg-body {
  min-height: 5rem;
  padding: 2rem 2rem;
}
