.accordion {
    width: 100%;
    /* max-width: 470px; */
  }
  .panel {
    background-color: #02012d;
  }
  .panel__label {
    color: rgba(255, 255, 255, 0.554);
    position: relative;
    display: block;
    width: 100%;
    background: none;
    border: none;
    font-family: "Clash Display Medium" !important ;
    text-align: left;
    padding: 25px 60px 25px 25px;
    font-weight: 500;
    font-size: 17px;
    font-family: inherit;
    transition: color 0.2s linear;
    cursor: pointer;
  }
  .panel__label:focus {
    outline: none;
  }
  .panel__label:after,
  .panel__label:before {
    content: '';
    position: absolute;
    right: 25px;
    top: 50%;
    width: 22px;
    height: 2px;
    margin-top: -2px;
    background-color: #fff;
  }
  .panel__label:before {
    transform: rotate(-90deg);
    transition: transform 0.35s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .panel[aria-expanded='true'] .panel__content {
    opacity: 1;
  }
  .panel[aria-expanded='true'] .panel__label {
    color: white;
    font-family: "Clash Display Medium";
  }
  .panel[aria-expanded='true'] .panel__label:before {
    transform: rotate(0deg);
  }
  .panel__inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
  .panel__content {
    margin: 5px 25px 25px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.608);
    font-family: "Clash Display Light";
    opacity: 0;
    transition: opacity 0.3s linear 0.18s;
  }
  .panel:not(:last-child) {
    margin-bottom: 3px;
  }
  