.logo {
  margin-top: 25px;
  height: 75px;
  width: auto;
}
.main-signup {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  background-color: #02012d !important;
}
.height-vh {
  height: 100vh;
}
.height-vh-col {
  align-items: center;
}

.card {
  position: relative;
  border: none !important;
  background-color: transparent !important;
  align-self: center;
  justify-self: center;
}
.logo3 {
  position: relative;
  height: 180vh;
  margin-left: -20vw;
  margin-top: -40vh !important;
  display: flex;
}
.box {
  /* margin-top: 20px; */
  width: 30%;
  min-width: 350px;
  /* max-width: 600px !important; */
  border: 0.5px solid white;
  padding: 40px;
  position: relative;
  justify-content: center;
  background: #02012d;
  border-radius: 20px;
  transition: 0.25s;
  align-items: center;
  font-family: "Clash Display Bold";
}
.box-header {
  font-size: 17px;
  color: white;
  text-align: center;
  /* background: linear-gradient(to right, #b92b27, #1565c0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  font-family: "Clash Display Light";
}
.box-header-text {
  font-size: 25px;
  font-family: "Clash Display Bold";
}
.box-header hr {
  background: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 30px;
}

.box input[type="text"],
.box input[type="password"],
.box input[as="select"],
.box select {
  border: 0;
  background: none;
  display: block;
  border-bottom: 1px solid #3498db;
  padding: 10px 10px;
  margin-bottom: 10px;
  width: 100%;
  font-family: "Clash Display Medium";
  outline: none;
  color: white;
  transition: 0.25s;
}
.box select {
  padding-right: 20px !important;
  background-color: #02012d !important;
}
.box select::placeholder {
  color: white;
  opacity: 0.5;
}
.box h1 {
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  font-family: "Clash Display Medium";
  text-align: center;
}

.box input[type="text"]:focus,
.box input[type="password"]:focus {
  /* width: 300px; */
  border: 1px solid linear-gradient(to right, red, purple);
}
.box input:-webkit-autofill {
  background-color: transparent !important;
}
.box input:autofill {
  background-color: transparent !important;
}
.box Label {
  color: gray;
}

.logo2 {
  position: relative;
  height: 15vh;
  width: auto;
}

.signup {
  text-decoration: none;
  color: gray;
  font-size: 13px;
  text-align: center;
  padding-top: 15px;
  /* font-family: 'Times New Roman', Times, serif; */
}
.signup a {
  text-decoration: none;
}
.button-submit {
  display: flex;
  justify-content: center;
  width: 100%;
}
.button {
  margin-top: 10px;
  /* background: linear-gradient(to right, #b92b27, #1565c0); */
  /* -webkit-background-clip: text; */
  /* -webkit-text-fill-color: transparent; */
  background-color: #1b72e8;
  border: 1px solid white;
  color: white;
  text-transform: uppercase;
  padding: 8px 6px;
  border-radius: 5px;
  font-family: "Clash Display Medium";
}
/* @media screen and (min-width: 720px)  {
    .box{
        width: ;
    }
} */
